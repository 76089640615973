import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import ServicesHome from "../Services/ServicesHome_2";
import { Link } from "react-router-dom";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="md:w-[98%] mx-auto my-12 text-center">
        <h2 className="text-center py-4">{rpdata?.labels?.general?.titleServices}</h2>
        <Link
          className={`
                        boton
                        my-3
                        three
                        px-6
                        py-4
                        w-52
                        rounded-lg
                    `}
          to={`/products`}
          onClick={goToTop}
        >
          <span className='capitalize'>
            view catalog
          </span>
        </Link>
        <div className="flex flex-wrap items-center justify-center">
          {rpdata?.dbServices?.map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ContentServicesHome;
