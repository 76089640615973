import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      // className="breadcrumb-area"
      className="w-full h-[50vh] bg-[#0C253A] bg-fondo-tHeader "
    >
      <div className="text-center flex flex-col justify-end w-full h-full pb-[10%] relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
